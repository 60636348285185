import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  deserializeProject,
  getDefaultOptionsValues,
  getOptionsConfig,
  serializeProject,
} from '@rawgraphs/rawgraphs-core'
import HeaderItems from './HeaderItems'
import Header from './components/Header'
import Section from './components/Section'
import Footer from './components/Footer'
import DataLoader from './components/DataLoader'
import ChartSelector from './components/ChartSelector'
import DataMapping from './components/DataMapping'
import ChartPreviewWithOptions from './components/ChartPreviewWIthOptions'
import ShareEmbed from './components/ShareEmbed'
import get from 'lodash/get'
import find from 'lodash/find'
import usePrevious from './hooks/usePrevious'
import baseCharts from './charts'
import useSafeCustomCharts from './hooks/useSafeCustomCharts'
import useDataLoader from './hooks/useDataLoader'
import isPlainObject from 'lodash/isPlainObject'
import CustomChartLoader from './components/CustomChartLoader'
import CustomChartWarnModal from './components/CustomChartWarnModal'
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js'
import { setFingerPrint } from './storage'
import WarningMessage from './components/WarningMessage'
import { useNavigate, useParams } from 'react-router-dom'
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'
import { db } from './firebase'
import { FIREBASE } from './constants'
import './styles/importer.scss'
import useWindowSize from './hooks/useWindowSize'
import { getDomain } from './utils'

function App() {
  const navigate = useNavigate()
  const size = useWindowSize()

  const [
    customCharts,
    {
      toConfirmCustomChart,
      confirmCustomChartLoad,
      abortCustomChartLoad,
      uploadCustomCharts,
      loadCustomChartsFromUrl,
      loadCustomChartsFromNpm,
      importCustomChartFromProject,
      removeCustomChart,
      exportCustomChart,
    },
  ] = useSafeCustomCharts()
  const charts = useMemo(() => baseCharts.concat(customCharts), [customCharts])

  const dataLoader = useDataLoader()
  const {
    userInput,
    userData,
    userDataType,
    parseError,
    unstackedData,
    unstackedColumns,
    data,
    separator,
    thousandsSeparator,
    decimalsSeparator,
    locale,
    stackDimension,
    dataSource,
    loading,
    hydrateFromSavedProject,
  } = dataLoader

  /* From here on, we deal with viz state */
  const [currentChart, setCurrentChart] = useState(charts[0])
  const [mapping, setMapping] = useState({})
  const [visualOptions, setVisualOptions] = useState(() => {
    const options = getOptionsConfig(charts[0]?.visualOptions)
    return getDefaultOptionsValues(options)
  })
  const [rawViz, setRawViz] = useState(null)
  const [mappingLoading, setMappingLoading] = useState(false)
  const dataMappingRef = useRef(null)

  const columnNames = useMemo(() => {
    if (get(data, 'dataTypes')) {
      return Object.keys(data.dataTypes)
    }
  }, [data])

  const prevColumnNames = usePrevious(columnNames)
  const clearLocalMapping = useCallback(() => {
    if (dataMappingRef.current) {
      dataMappingRef.current.clearLocalMapping()
    }
  }, [])

  const getCloudClients = async () => {
    const querySnapshot = await getDocs(collection(db, FIREBASE['CLIENTS']))
    const cloudClients = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))
    return [].concat(...cloudClients)
  }

  const [fingerPrintSet, isFingerPrintSet] = useState(false)
  useEffect(() => {
    if (!fingerPrintSet) {
      getCurrentBrowserFingerPrint().then(async (fingerprint) => {
        setFingerPrint(fingerprint + '', await getCloudClients())
      })
    }
    return () => {
      isFingerPrintSet(true)
    }
  })

  // NOTE: When we run the import we want to use the "last"
  // version of importProject callback
  const lasImportProjectRef = useRef()
  useEffect(() => {
    lasImportProjectRef.current = importProject
  })
  useEffect(() => {
    const projectUrlStr = new URLSearchParams(window.location.search).get('url')
    let projectUrl
    try {
      projectUrl = new URL(projectUrlStr)
    } catch (e) {
      // BAD URL
      return
    }
    fetch(projectUrl)
      .then((r) => (r.ok ? r.text() : Promise.reject(r)))
      .then(
        (projectStr) => {
          const project = deserializeProject(projectStr, baseCharts)
          const lastImportProject = lasImportProjectRef.current
          if (lastImportProject) {
            lastImportProject(project, true)
          }
        },
        (err) => {
          console.log(`Can't load ${projectUrl}`, err)
        },
      )
  }, [])

  //resetting mapping when column names changes (ex: separator change in parsing)
  useEffect(() => {
    if (prevColumnNames) {
      if (!columnNames) {
        setMapping({})
        clearLocalMapping()
      } else {
        const prevCols = prevColumnNames.join('.')
        const currentCols = columnNames.join('.')
        if (prevCols !== currentCols) {
          setMapping({})
          clearLocalMapping()
        }
      }
    }
  }, [columnNames, prevColumnNames, clearLocalMapping])

  // update current chart when the related custom charts change under the hood
  // if the related custom chart is removed set the first chart
  useEffect(() => {
    if (currentChart.rawCustomChart) {
      const currentCustom = find(
        customCharts,
        (c) => c.metadata.id === currentChart.metadata.id,
      )
      if (!currentCustom) {
        setCurrentChart(baseCharts[0])
        return
      }
      if (
        currentCustom.rawCustomChart.source !==
        currentChart.rawCustomChart.source
      ) {
        setCurrentChart(currentCustom)
      }
    }
  }, [customCharts, currentChart])

  const handleChartChange = useCallback(
    (nextChart) => {
      setMapping({})
      clearLocalMapping()
      setCurrentChart(nextChart)
      const options = getOptionsConfig(nextChart?.visualOptions)
      setVisualOptions(getDefaultOptionsValues(options))
      setRawViz(null)
    },
    [clearLocalMapping],
  )

  const exportProject = useCallback(async () => {
    const customChart = await exportCustomChart(currentChart)
    return serializeProject({
      userInput,
      userData,
      userDataType,
      parseError,
      unstackedData,
      unstackedColumns,
      data,
      separator,
      thousandsSeparator,
      decimalsSeparator,
      locale,
      stackDimension,
      dataSource,
      currentChart,
      mapping,
      visualOptions,
      customChart,
    })
  }, [
    currentChart,
    data,
    dataSource,
    decimalsSeparator,
    locale,
    mapping,
    parseError,
    separator,
    stackDimension,
    thousandsSeparator,
    userData,
    userDataType,
    userInput,
    visualOptions,
    unstackedColumns,
    unstackedData,
    exportCustomChart,
  ])

  // project import
  const importProject = useCallback(
    async (project, fromUrl) => {
      let nextCurrentChart
      if (project.currentChart.rawCustomChart) {
        try {
          nextCurrentChart = await importCustomChartFromProject(
            project.currentChart,
          )
        } catch (err) {
          if (err.isAbortByUser) {
            if (fromUrl) {
              // NOTE: clean the url when the user abort loading custom js
              window.history.replaceState(null, null, '/')
            }
            return
          }
          throw err
        }
      } else {
        nextCurrentChart = project.currentChart
      }
      hydrateFromSavedProject(project)
      nextCurrentChart.id = project.id
      setCurrentChart(nextCurrentChart)
      setMapping(project.mapping)
      // adding "annotations" for color scale:
      // we annotate the incoming options values (complex ones such as color scales)
      // to le the ui know they are coming from a loaded project
      // so we don't have to re-evaluate defaults
      // this is due to the current implementation of the color scale
      const patchedOptions = { ...project.visualOptions }
      Object.keys(patchedOptions).forEach((k) => {
        if (isPlainObject(patchedOptions[k])) {
          patchedOptions[k].__loaded = true
        }
      })
      setVisualOptions(project.visualOptions)
    },
    [hydrateFromSavedProject, importCustomChartFromProject],
  )

  const { sharedChartId } = useParams()
  const [sharedChart, setSharedChart] = useState(null)
  useEffect(() => {
    (async () => {
      if (sharedChartId) {
        try {
          // get chart from Firebase
          const docRef = doc(db, FIREBASE['COLLECTION'], sharedChartId)
          const docSnap = await getDoc(docRef)
          let cloudChart
          if (docSnap.exists()) {
            cloudChart = docSnap.data()
          } else {
            console.log('Document does not exist')
            setShareError(`チャート 「${sharedChartId}」は見つかりません。ご確認お願いいたします。`)
            navigate('/')
            return
          }
          setSharedChart(cloudChart)

          // import project from Firebase
          const projectUrl = cloudChart.sample.project
          const response = await fetch(projectUrl)
          const projectJson = await response.text()
          const project = deserializeProject(projectJson, charts)
          await importProject(project)

          let options = project.visualOptions
          options.width = options.showLegend ? size.width - options.legendWidth : size.width
          options.height = size.height - 40
          setVisualOptions(options)
        } catch (error) {
          console.log(error)
          setShareError(`チャートローディングにエラーは発生しました。申し訳ございませんが、再度試してください。`)
          navigate('/')
        }
      }
    })()
  }, [
    sharedChartId,
    charts,
    importProject,
    navigate,
    size,
  ])

  const [isModalCustomChartOpen, setModalCustomChartOpen] = useState(false)
  const toggleModalCustomChart = useCallback(
    () => setModalCustomChartOpen((o) => !o),
    [],
  )

  const [shareError, setShareError] = useState()

  return (
    <div className="App">
      {!sharedChartId && <Header menuItems={HeaderItems} />}
      <CustomChartWarnModal
        isOpen={isModalCustomChartOpen}
        onClose={toggleModalCustomChart}
        toConfirmCustomChart={toConfirmCustomChart}
        confirmCustomChartLoad={confirmCustomChartLoad}
        abortCustomChartLoad={abortCustomChartLoad}
      />
      <div className="app-sections">
        {sharedChartId && data && currentChart && (
          <div className="importerChart">
            <ChartPreviewWithOptions
              chart={currentChart}
              dataset={data.dataset}
              dataTypes={data.dataTypes}
              mapping={mapping}
              visualOptions={visualOptions}
              setVisualOptions={setVisualOptions}
              setRawViz={setRawViz}
              setMappingLoading={setMappingLoading}
              chartOnly={true}
            />
            <div className="importerFooter">
              <div className="importerFooterLeft">
                <span className="importerFooterBlock">
                  {sharedChart.desc ?? sharedChart.title ?? sharedChart.categoryJp}
                </span>
                <span className="importerFooterSeparator" />
                <span className="importerFooterBlock">
                  Created with <a className="importerFooterLink" href={getDomain()} target="_blank" rel="noreferrer">Mini Chart</a>
                </span>
              </div>
            </div>
          </div>
        )}
        {!sharedChartId && (<>
            <Section title={``} loading={loading}>
              <DataLoader {...dataLoader} hydrateFromProject={importProject} />
            </Section>
            {data && (
              <Section title="１、チャート選択">
                <CustomChartLoader
                  isOpen={isModalCustomChartOpen}
                  onClose={toggleModalCustomChart}
                  loadCustomChartsFromNpm={loadCustomChartsFromNpm}
                  loadCustomChartsFromUrl={loadCustomChartsFromUrl}
                  uploadCustomCharts={uploadCustomCharts}
                />
                <ChartSelector
                  onAddChartClick={toggleModalCustomChart}
                  onRemoveCustomChart={removeCustomChart}
                  availableCharts={charts}
                  currentChart={currentChart}
                  setCurrentChart={handleChartChange}
                />
              </Section>
            )}
            {data && currentChart && (
              <Section title={`２、データマッピング`} loading={mappingLoading}>
                <DataMapping
                  ref={dataMappingRef}
                  dimensions={currentChart.dimensions}
                  dataTypes={data.dataTypes}
                  mapping={mapping}
                  setMapping={setMapping}
                />
              </Section>
            )}
            {data && currentChart && (
              <Section title="３、カスタマイズ">
                <ChartPreviewWithOptions
                  chart={currentChart}
                  dataset={data.dataset}
                  dataTypes={data.dataTypes}
                  mapping={mapping}
                  visualOptions={visualOptions}
                  setVisualOptions={setVisualOptions}
                  setRawViz={setRawViz}
                  setMappingLoading={setMappingLoading}
                />
              </Section>
            )}
            {data && currentChart && rawViz && (
              <Section title="４、チャート共有">
                <ShareEmbed
                  rawViz={rawViz}
                  exportProject={exportProject}
                  setShareError={setShareError}
                  chartId={currentChart.id}
                />
              </Section>
            )}
            <Footer />
          </>
        )}
        {shareError && (
          <WarningMessage
            variant="danger"
            message={shareError}
          />
        )}
      </div>
    </div>
  )
}

export default App
