import React from 'react'
import styles from './Header.module.scss'
import { Navbar, Nav } from 'react-bootstrap'
import logo from '../../images/mini-chart_0.5.png'
import packageJson from '../../../package.json'

export default function Header({ menuItems }) {
  return (
    <Navbar style={{ backgroundColor: 'var(--dark)' }} expand="lg" sticky="top" className={styles.navbar}>
      <Navbar.Brand href="/">
        <img src={logo} alt="minichart" />
        <label style={{ color: 'var(--light)', paddingLeft: 10 }}>v{packageJson.version}</label>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {menuItems.map((d, i) => {
            return (
              <Nav.Link key={'item' + i} href={d.href} style={{ color: 'var(--light)' }}>
                {d.label}
              </Nav.Link>
            )
          })}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
