import { FIREBASE, LOCAL_STORAGE } from './constants'
import { indexOf, remove, concat, last, filter, union } from 'lodash'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { db } from './firebase'

const findFromCloud = (fingerprint, clients) => {
  const clientGroup = filter(clients, clientGroup => {
    return indexOf(clientGroup.mcids, fingerprint) !== -1
  })
  return clientGroup[0] ?? {mcids: []}
}

export const setFingerPrint = (fingerprint, clients) => {
  const cloudClients = findFromCloud(fingerprint, clients)
  let allClients = union(getFingerPrint(), cloudClients.mcids)
  remove(allClients, (item) => item === fingerprint)
  allClients = concat(allClients, fingerprint)
  localStorage.setItem(LOCAL_STORAGE['FINGERPRINT'], JSON.stringify(allClients))

  try {
    cloudClients.mcids = allClients
    if (cloudClients.id) {
      setDoc(doc(db, FIREBASE['CLIENTS'], cloudClients.id), cloudClients)
    } else {
      addDoc(collection(db, FIREBASE['CLIENTS']), cloudClients)
    }
  } catch (err) {
    console.log(err)
  }
}

const isString = (value) => {
  return (typeof value === 'string' || value instanceof String) || (typeof value === 'number' || value instanceof Number)
}

export const getFingerPrint = () => {
  const fp = JSON.parse(localStorage.getItem(LOCAL_STORAGE['FINGERPRINT']) ?? '[]')
  return isString(fp) ? [fp + ''] : fp
}

export const getLatestFingerPrint = () => {
  return last(getFingerPrint())
}
