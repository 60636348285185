import { Col, Card, Modal } from 'react-bootstrap'
import styles from './DetailModal.module.scss'
import React from 'react'
import classNames from 'classnames'
import { GoReply } from 'react-icons/go'
import { filter } from 'lodash'
import { samplesList } from '../../DataSamples/DataSamples'
import { deserializeProject } from '@rawgraphs/rawgraphs-core'
import charts from '../../../charts'

export default function DetailModal({
  chart,
  abortChartLoad,
  onProjectSelected,
  setLoadingError,
  onUseGalleryChart
}) {
  const displayCount = (count) => {
    let thumbup = count
    if (thumbup === 0) {
      thumbup = '-'
    } else if (thumbup > 999 && thumbup <= 9999) {
      thumbup = parseInt(thumbup / 1000)
      thumbup = parseInt(thumbup) + '千以上'
    } else if (thumbup > 9999 && thumbup <= 9999999) {
      thumbup = parseInt(thumbup / 10000)
      thumbup = parseInt(thumbup) + '万以上'
    } else if (thumbup > 9999999) {
      thumbup = parseInt(thumbup / 10000000)
      thumbup = parseInt(thumbup) + '千万以上'
    }
    return thumbup
  }

  const timeSince = (timestamp) => {
    const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000)
    let interval = seconds / 31536000
    if (interval > 1) {
      return Math.floor(interval) + '年前'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + '月前'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + '日前'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + '時間前'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + '分前'
    }
    return Math.floor(seconds) + '秒前'
  }

  const findDescription = (category) => {
    const sampleChart = filter(samplesList, sample => {
      return sample.category === category
    })[0]
    return sampleChart.descUrl
  }

  const toUseChart = async (chart) => {
    const projectUrl = chart.sample.project
    let response
    try {
      response = await fetch(projectUrl)
    } catch (e) {
      setLoadingError('読み込みエラー：' + e.message)
      return
    }
    const projectJson = await response.text()
    try {
      const project = deserializeProject(projectJson, charts)
      setLoadingError(null)
      onProjectSelected(project)
      onUseGalleryChart(chart)
    } catch (e) {
      setLoadingError(e.message)
    }
  }

  return (
    <Modal
      show={chart !== null}
      onHide={() => abortChartLoad(null)}
      backdrop="static"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName='border'
      dialogClassName={styles.chartModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {chart && chart.sample.svg && (
          <>
            <Col className={styles.chartModalCol}>
              <Card className={classNames(`flex-row h-100 ${styles.chartModalCard}`)}>
                <div
                  className={`h-100 w-75 ${styles.thumbnail}`}
                  style={{ backgroundImage: `url("${chart.sample.svg}")` }}
                  title={chart.title ?? chart.categoryJp}
                ></div>
                <Card.Body className="w-25 px-2 py-3">
                  <Card.Title className={classNames(`m-0 ml-5 mr-5 ${styles.chartModalCardTitle}`)}>
                    <div className={styles.block}>
                      <div className={styles.kicker}>チャートID</div>
                      <h2 className={styles.chartId}>{chart.id}</h2>
                    </div>
                    <div className={styles.block}>
                      <div className={styles.kicker}>作成者</div>
                      <span>{chart.mcid}</span></div>
                    <div className={classNames(`${styles.block} ${styles.columns}`)}>
                      <div className={styles.column}>
                        <div className={styles.kicker}>作成時間</div>
                        {timeSince(chart.timestamp)}
                      </div>
                      <div className={styles.column}>
                        <div className={styles.kicker}>更新時間</div>
                        {timeSince(chart.updateTimestamp ?? chart.timestamp)}
                      </div>
                      <div className={styles.column}>
                      </div>
                    </div>
                    <div className={classNames(`${styles.block} ${styles.columns}`)}>
                      <div className={styles.column}>
                        <div className={styles.kicker}>いいね</div>
                        {displayCount(chart.statistics.thumbup)}
                      </div>
                      <div className={styles.column}>
                        <div className={styles.kicker}>ヒット</div>
                        {displayCount(chart.statistics.clicked)}
                      </div>
                      <div className={styles.column}>
                        <div className={styles.kicker}>再利用</div>
                        {displayCount(chart.statistics.used)}
                      </div>
                    </div>
                    <hr className="mt-2" />
                    <div>
                      {chart.desc ?? chart.title ?? chart.categoryJp}
                    </div>
                    <div className="mt-5 mb-4">
                      <button
                        className="btn btn-lg btn-primary"
                        onClick={(e) => {
                          e.stopPropagation()
                          toUseChart(chart)
                        }}
                      >
                        <GoReply />再利用
                      </button>
                    </div>
                    <hr className="my-2" />
                    <p>
                      このチャートに関する具体的な紹介を知りたい場合は、
                      <a href={findDescription(chart.category)} target="_blank" rel="noreferrer">visualizing.jp</a>
                      をご参照ください。
                    </p>
                  </Card.Title>
                </Card.Body>
              </Card>
            </Col>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
