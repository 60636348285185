// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDdvOoQltpu0pGVPuIQOTSzVEkb0B9U8N8",
  authDomain: "minichart-eeef7.firebaseapp.com",
  projectId: "minichart-eeef7",
  storageBucket: "minichart-eeef7.appspot.com",
  messagingSenderId: "762257234741",
  appId: "1:762257234741:web:e6659e58d588c4c71e8a7c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
