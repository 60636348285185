import React, { useState, useCallback, useMemo } from 'react'
import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import uniq from 'lodash/uniq'
import styles from './GallerySelector.module.scss'
import { deserializeProject } from '@rawgraphs/rawgraphs-core'
import charts from '../../charts'
import classNames from 'classnames'
import { getFingerPrint, getLatestFingerPrint } from '../../storage'
import { GoEye, GoReply, GoThumbsup } from 'react-icons/go'
import ChartModal from './ChartModal'
import DetailModal from './DetailModal'
import { indexOf } from 'lodash'

function filterCharts(chartsFiltered, filter) {
  return filter === 'すべてのチャート'
    ? chartsFiltered
    : chartsFiltered.filter((d) => d.categoryJp === filter)
}

function GallerySelector({
  isSelfCharts,
  availableCharts,
  onProjectSelected,
  setLoadingError,
  onUseGalleryChart,
  onThumbupGalleryChart,
  onClickGalleryChart,
  onPublicGalleryChart,
  onDeleteGalleryChart,
}) {
  const [filter, setFilter] = useState('すべてのチャート')
  const [selectedChart, setSelectedChart] = useState(null)
  const [clickedChart, setClickedChart] = useState(null)

  const chartsFiltered = useMemo(() => {
    return filterCharts(availableCharts, filter)
  }, [availableCharts, filter])

  const handleFilterChange = useCallback(
    (nextFilter) => {
      setFilter(nextFilter)
    },
    [],
  )

  const clickChartImage = useCallback(
    async (chart) => {
      setClickedChart(chart)
      onClickGalleryChart(chart)
    }, [onClickGalleryChart])

  const canThumbup = (chart) => {
    if (!chart) return false
    const mcid = chart.mcid
    const fingerprint = getFingerPrint()
    return fingerprint && mcid && indexOf(fingerprint, mcid) === -1 && chart.statistics.thumbupBy.indexOf(getLatestFingerPrint()) === -1
  }

  const canDelete = (chart) => {
    if (!chart) return false
    const mcid = chart.mcid
    const fingerprint = getFingerPrint()
    return fingerprint && mcid && indexOf(fingerprint, mcid) !== -1
  }

  const canPublic = (chart) => {
    if (!canDelete(chart)) return false
    return !chart.public
  }

  const canEdit = (chart) => {
    if (!canDelete(chart)) return false
    return chart.public === false
  }

  const displayCount = (count) => {
    let thumbup = count
    if (thumbup === 0) {
      thumbup = ''
    } else if (thumbup > 999 && thumbup <= 9999) {
      thumbup = parseInt(thumbup / 1000)
      thumbup = parseInt(thumbup) + '千以上'
    } else if (thumbup > 9999 && thumbup <= 9999999) {
      thumbup = parseInt(thumbup / 10000)
      thumbup = parseInt(thumbup) + '万以上'
    } else if (thumbup > 9999999) {
      thumbup = parseInt(thumbup / 10000000)
      thumbup = parseInt(thumbup) + '千万以上'
    }
    return thumbup
  }

  const timeSince = (timestamp) => {
    const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000)
    let interval = seconds / 31536000
    if (interval > 1) {
      return Math.floor(interval) + '年前'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + '月前'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + '日前'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + '時間前'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + '分前'
    }
    return Math.floor(seconds) + '秒前'
  }

  const toUseChart = async (chart) => {
    const projectUrl = chart.sample.project
    let response
    try {
      response = await fetch(projectUrl)
    } catch (e) {
      setLoadingError('読み込みエラー：' + e.message)
      return
    }
    const projectJson = await response.text()
    try {
      const project = deserializeProject(projectJson, charts)
      setLoadingError(null)
      onProjectSelected(project)
      onUseGalleryChart(chart)
    } catch (e) {
      setLoadingError(e.message)
    }
  }

  const abortChartLoad = async () => {
    setSelectedChart(null)
  }

  const abortChartDetail = async () => {
    setClickedChart(null)
  }

  const editChart = async (chart) => {
    const projectUrl = chart.sample.project
    let response
    try {
      response = await fetch(projectUrl)
    } catch (e) {
      setLoadingError('読み込みエラー：' + e.message)
      return
    }
    const projectJson = await response.text()
    try {
      const project = deserializeProject(projectJson, charts)
      setLoadingError(null)
      project.id = chart.id
      onProjectSelected(project)
    } catch (e) {
      setLoadingError(e.message)
    }
  }

  return (
    <>
      <ChartModal chart={selectedChart} abortChartLoad={abortChartLoad} />
      <DetailModal
        chart={clickedChart}
        abortChartLoad={abortChartDetail}
        onProjectSelected={onProjectSelected}
        setLoadingError={setLoadingError}
        onUseGalleryChart={onUseGalleryChart}
      />
      <Row>
        <Col className="text-right">
          チャートタイプ
          <Dropdown className="d-inline-block ml-2 raw-dropdown">
            <Dropdown.Toggle variant="white" className="pr-5">
              {filter.charAt(0).toUpperCase() + filter.slice(1)}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                key={'すべてのチャート'}
                onClick={() => handleFilterChange('すべてのチャート')}
              >
                すべてのチャート
              </Dropdown.Item>
              {uniq(
                availableCharts.map((d) => d.categoryJp).flat(),
              ).map((d) => {
                return (
                  <Dropdown.Item key={d} onClick={() => handleFilterChange(d)}>
                    {d.charAt(0).toUpperCase() + d.slice(1)}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        {chartsFiltered
          // sort by published timestamp
          .sort((a, b) => ('' + b.timestamp).localeCompare('' + a.timestamp))
          .map((d, i) => {
            return (
              <Col xs={6} lg={6} xl={4} key={i}
                   className={classNames(`p-3 ${isSelfCharts ? styles.rowHeightSelf : styles.rowHeight}`)}>
                <Card className="h-100">
                  <Card.Body
                    className="d-flex flex-column"
                  >
                    {isSelfCharts && <button
                      style={{
                        position: 'absolute',
                        top: 1,
                        right: 1,
                        cursor: 'default',
                      }}
                      className={`btn btn-sm ${d.public ? 'btn-outline-success' : 'btn-outline-secondary'}`}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      {d.public ? '公開' : '個人'}
                    </button>}
                    <Card.Title className="">
                      <div className={styles.cardTitle}>{d.title ?? d.categoryJp}</div>
                      <div className={styles.cardSubTitle}>
                        <span className={styles.author}>{d.mcid}</span>
                        &nbsp;
                        <span className={styles.published}>{timeSince(d.timestamp)}</span>
                      </div>
                    </Card.Title>
                    <div
                      className={`h-100 w-100 cursor-pointer ${styles.thumbnail}`}
                      style={{ backgroundImage: `url("${d.sample.svg}")` }}
                      onClick={() => {
                        clickChartImage(d)
                      }}
                    ></div>
                    <Row className={`mt-2 ${styles.cardFooter}`}>
                      <Col
                        xs={isSelfCharts ? 12 : 6}
                        lg={isSelfCharts ? 12 : 6}
                        xl={isSelfCharts ? 12 : 6}
                        className={styles.cardFooterLeft}
                      >
                        {d.statistics.thumbup > 0 &&
                        <div className={`m-1 ${styles.btnLabel}`} title="いいね">
                          <GoThumbsup />
                          <span className="ml-2">{displayCount(d.statistics.thumbup)}</span>
                        </div>
                        }
                        {d.statistics.clicked > 0 &&
                        <div className={`m-1 ${styles.btnLabel}`} title="ヒット">
                          <GoEye />
                          <span className="ml-2">{displayCount(d.statistics.clicked)}</span>
                        </div>
                        }
                        {d.statistics.used > 0 &&
                        <div className={`m-1 ${styles.btnLabel}`} title="再利用">
                          <GoReply />
                          <span className="ml-2">{displayCount(d.statistics.used)}</span>
                        </div>
                        }
                      </Col>
                      <Col
                        xs={isSelfCharts ? 12 : 6}
                        lg={isSelfCharts ? 12 : 6}
                        xl={isSelfCharts ? 12 : 6}
                        className={isSelfCharts ? styles.cardFooterLeft : styles.cardFooterRight}
                      >
                        {canThumbup(d) && <div className="m-1">
                          <button
                            className={`btn ${!isSelfCharts ? 'btn-sm' : ''} btn-primary`}
                            onClick={(e) => {
                              e.stopPropagation()
                              onThumbupGalleryChart(d)
                            }}
                          >
                            いいね！
                          </button>
                        </div>
                        }
                        <div className="m-1">
                          <button
                            className={`btn ${!isSelfCharts ? 'btn-sm' : ''} btn-primary`}
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelectedChart(d)
                            }}
                          >
                            参照
                          </button>
                        </div>
                        <div className="m-1">
                          <button
                            className={`btn ${!isSelfCharts ? 'btn-sm' : ''} btn-primary`}
                            onClick={(e) => {
                              e.stopPropagation()
                              toUseChart(d)
                            }}
                          >
                            再利用
                          </button>
                        </div>
                        {isSelfCharts && <>
                          <div className="m-1">
                            <button
                              className={`btn ${!isSelfCharts ? 'btn-sm' : ''} btn-primary`}
                              onClick={(e) => {
                                e.stopPropagation()
                                onPublicGalleryChart(d)
                              }}
                            >
                              {canPublic(d) ? '公開' : '非公開'}
                            </button>
                          </div>
                          {canEdit(d) && <div className="m-1">
                            <button
                              className={`btn ${!isSelfCharts ? 'btn-sm' : ''} btn-primary`}
                              onClick={(e) => {
                                e.stopPropagation()
                                editChart(d)
                              }}
                            >
                              編集
                            </button>
                          </div>
                          }
                          {canDelete(d) && <div className="m-1">
                            <button
                              className={`btn ${!isSelfCharts ? 'btn-sm' : ''} btn-warning`}
                              onClick={(e) => {
                                e.stopPropagation()
                                onDeleteGalleryChart(d)
                              }}
                            >
                              削除
                            </button>
                          </div>
                          }
                        </>}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )
          })}
      </Row>
    </>
  )
}

export default React.memo(GallerySelector)
