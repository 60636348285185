import React, { useCallback } from 'react'
import { Button } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'
import S from './UploadFile.module.scss'
import Encoding from 'encoding-japanese'
import * as XLSX from 'xlsx/xlsx.mjs'

export default function UploadFile({
  setUserInput,
  setLoadingError,
}) {
  const isExcel = (file) => {
    const fileTypes = ['xls', 'xlsx', 'xlsm'];
    const extension = file.name.split('.').pop().toLowerCase();
    return fileTypes.indexOf(extension) > -1;
  }
  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader()
      reader.addEventListener('load', (e) => {
        // https://qiita.com/NoSuke23/items/adc2b441d8c77b582b4e
        // ブラウザ上でcsvやxlsx等のシートを読み込む
        let text;
        // 8ビット符号なし整数値配列と見なす
        let array = new Uint8Array(e.target.result);

        if (isExcel(acceptedFiles[0])) {
          let workbook = XLSX.read(array, {type: "array"});
          text = XLSX.utils.sheet_to_csv(workbook.Sheets[workbook.SheetNames[0]]);
        } else {
          // 文字コードを取得
          switch (Encoding.detect(array)) {
            case 'UTF16':
              // 16ビット符号なし整数値配列と見なす
              array = new Uint16Array(e.target.result);
              break;
            case 'UTF32':
              // 32ビット符号なし整数値配列と見なす
              array = new Uint32Array(e.target.result);
              break;
            default:
          }

          // Unicodeの数値配列に変換
          const unicodeArray = Encoding.convert(array, 'UNICODE');
          // Unicodeの数値配列を文字列に変換
          text = Encoding.codeToString(unicodeArray);
        }

        setUserInput(text)
        setLoadingError(null)
      })
      if (acceptedFiles.length) {
        reader.readAsArrayBuffer(acceptedFiles[0])
      }
    },
    [setLoadingError, setUserInput]
  )
  const {
    getRootProps,
    getInputProps,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop,
    accept:
      'text/csv,text/plain,application/json,application/vnd.ms-excel,text/tsv,text/tab-separated-values',
    maxFiles: 1,
  })
  return (
    <div
      className={classNames(S.dropzone, {
        [S.reject]: isDragReject,
        [S.accept]: isDragAccept,
      })}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <span>ここにファイルをドラッグするか、またはコンピュータからファイルを </span>
      <Button className={S['browse-button']} color="primary">
        参照
      </Button>
      <span>してください</span>
      {isDragAccept && <span style={{color: 'var(--success)'}}>&nbsp;&nbsp;すべてのファイルが受け入れられます</span>}
      {isDragReject && <span style={{color: 'var(--warning)'}}>&nbsp;&nbsp;一部のファイルは拒否されます</span>}
    </div>
  )
}
