import React from 'react'
import styles from './Footer.module.scss'
import { Row, Col, Container } from 'react-bootstrap'

// #TODO add commit hash
// const commitHash = process.env.REACT_APP_VERSION || 'dev'

export default function Footer(props) {
  return (
    <Container fluid style={{ backgroundColor: 'var(--dark)' }}>
      <Container className={styles.footer}>
        <Row>
          <Col xs={24} sm={24} lg={24} style={{ width: '100%', textAlign: 'center' }}>
            <span>Copyright © 2023- Napir All Rights Reserved.</span>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
