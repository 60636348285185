import { Modal } from 'react-bootstrap'
import styles from './ChartModal.module.scss'
import React from 'react'
import { CopyToClipboardButton } from '../../CopyToClipboardButton'
import { getDomain } from '../../../utils'

export default function ChartModal({ chart, abortChartLoad }) {
  const chartEmbedSrc = () => {
    return `<img src="${chart.sample.svg}" alt="minichart-${chart.title ?? chart.categoryJp}"`
  }

  return (
    <Modal
      show={chart !== null}
      onHide={() => abortChartLoad(null)}
      backdrop="static"
      centered
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName='border'
      dialogClassName={styles.chartModalDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {chart && chart.sample.svg && (
          <>
            <div className={styles.chartModalBody}>
              <img src={chart.sample.svg} alt={chart.title ?? chart.categoryJp} />
            </div>
            <div className="row">
              <div className="d-flex align-items-center nowrap col-sm-1">
                チャートURL
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control text-field"
                  value={`${getDomain()}/${chart.id}`}
                  readOnly={true}
                ></input>
              </div>
              <div className="col col-sm-2 d-flex">
                <CopyToClipboardButton content={`${getDomain()}/${chart.id}`} />
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center nowrap col-sm-1">
                プロジェクトURL
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control text-field"
                  value={chart.sample.project_s??chart.sample.project}
                  readOnly={true}
                ></input>
              </div>
              <div className="col col-sm-2 d-flex">
                <CopyToClipboardButton content={chart.sample.project_s??chart.sample.project} />
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center nowrap col-sm-1">
                埋め込みコード
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control text-field"
                  value={chartEmbedSrc()}
                  readOnly={true}
                ></input>
              </div>
              <div className="col col-sm-2 d-flex">
                <CopyToClipboardButton content={chartEmbedSrc()} />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}
