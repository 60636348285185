import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'

function DataMismatchModal({
  replaceRequiresConfirmation,
  commitDataReplace,
  cancelDataReplace,
}) {
  const [showModal, setShowModal] = useState(true)

  const handleClose = () => {
    setShowModal(false)
  }
  return (
    <Modal
      className="raw-modal"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title as="h5">
          警告：{' '}
          {replaceRequiresConfirmation === 'parse-error' && <>解析エラー</>}
          {replaceRequiresConfirmation.startsWith('missing-column:') && (
            <>列の欠落</>
          )}
          {replaceRequiresConfirmation === 'type-mismatch' && (
            <>データ型の不一致</>
          )}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {replaceRequiresConfirmation === 'parse-error' && (
          <>
            <p>新しいデータの解析中にエラーが発生しました。</p>
            <p>
              新しいデータを使用してデータマッピングを作成することも、
              以前にロードしたデータに戻ることもできます。
            </p>
          </>
        )}
        {replaceRequiresConfirmation.startsWith('missing-column:') && (
          <>
            <p>
              このプロジェクトのデータマッピングには列{' '}
              <span className="font-weight-bold">
                {replaceRequiresConfirmation.split(':')[1]}
              </span>
              が必要ですが、新しいデータでは見つかりません。
            </p>
            <p>
              新しいデータを使用してデータマッピングを作成することも、
              以前にロードしたデータに戻ることもできます。
            </p>
          </>
        )}
        {replaceRequiresConfirmation === 'type-mismatch' && (
          <>
            <p>
              このプロジェクトに対して以前に設定されたデータ型を新しいデータに適用することはできません。
            </p>
            <p>
              新しいデータを使用してデータマッピングを作成することも、
              以前にロードしたデータに戻ることもできます。
            </p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="warning"
          onClick={() => {
            commitDataReplace()
          }}
        >
          新しいデータをロードする
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            cancelDataReplace()
          }}
        >
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DataMismatchModal
