import React from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { dataTypeIcons } from '../../constants'
import styles from './DataMapping.module.scss'

function DataTypeIcon({ type }) {
  const DataTypeIcon = dataTypeIcons[type]
  const translateLabel = (type) => {
    switch (type) {
      case 'string':
        return '文字列'
      case 'number':
        return '数値'
      case 'date':
        return '日付'
      default:
        return '未知タイプ'
    }
  }
  return (
    <span>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={
          <Tooltip id={`tooltip-top`}>
            {translateLabel(type)}を指定できる
          </Tooltip>
        }
      >
        <DataTypeIcon className={styles['accepted-type-icon']} />
      </OverlayTrigger>
    </span>
  )
}

export default DataTypeIcon
