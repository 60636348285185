import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import styles from './DataSamples.module.scss'
import { deserializeProject } from '@rawgraphs/rawgraphs-core'
import charts from '../../charts'

export const samplesList = [
  {
    name: 'Hate crimes in New York',
    category: 'Alluvial Diagram',
    url: './sample-datasets/Alluvial diagram - Hate crimes in New York.tsv',
    delimiter: '\t',
    sourceName: 'NYC Open Data',
    sourceURL:
      'https://data.cityofnewyork.us/Public-Safety/NYPD-Hate-Crimes/bqiq-cu78',
    nameJp: '憎悪犯罪',
    categoryJp: 'アルビアル・ダイアグラム',
    sample: {
      svg: './sample-charts/AlluvialDiagram/AlluvialDiagram.svg',
      project: './sample-charts/AlluvialDiagram/AlluvialDiagram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recY71ZEXVMePwZUw',
  },
  {
    name: 'Lannister vs Starck relationships',
    category: 'Arc diagrams',
    url:
      './sample-datasets/Arc diagrams - Lannister vs Starck relationships.tsv',
    delimiter: '\t',
    sourceName: 'T. Breid via Kaggle',
    sourceURL: 'https://www.kaggle.com/theobreid/got-data',
    nameJp: 'Lannister vs Starckの関係',
    categoryJp: 'アーク・ダイアグラム',
    sample: {
      svg: './sample-charts/ArcDiagram/ArcDiagram.svg',
      project: './sample-charts/ArcDiagram/ArcDiagram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recnjsHs4xGeWVJk4',
  },
  {
    name: 'Crime in Japan, 2008-2020',
    category: 'Bar chart',
    url: './sample-datasets-jp/Bar chart - Crime in Japan.tsv',
    delimiter: '\t',
    sourceName: 'e-Govデータポータル',
    sourceURL: 'https://data.e-gov.go.jp/data/ja/dataset/npa_20220530_0031/resource/831272d1-c2cd-4a82-908f-428aed781812?inner_span=True',
    nameJp: '日本の犯罪（2008-2020）',
    categoryJp: '棒グラフ',
    sample: {
      svg: './sample-charts/BarChart/BarChart.svg',
      project: './sample-charts/BarChart/BarChart.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recD6bYQg203JbhOJ',
  },
  {
    name: 'Population statistics',
    category: 'Multiset Barchart',
    url: './sample-datasets-jp/Multiset Barchart - Population by year-prefecture-gender.tsv',
    delimiter: '\t',
    sourceName: 'e-Govデータポータル',
    sourceURL: 'https://data.e-gov.go.jp/data/ja/dataset/mhlw_20201124_0048/resource/849cfd5d-d353-4927-b48d-0093620ed2b8?inner_span=True',
    nameJp: '年次・都道府県・性別人口統計',
    categoryJp: 'グループ棒グラフ',
    sample: {
      svg: './sample-charts/BarChartMultiset/BarChartMultiset.svg',
      project: './sample-charts/BarChartMultiset/BarChartMultiset.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recsSfBvr1geJDxzy',
  },
  {
    name: 'Energy consumption statistics',
    category: 'Stacked barchart',
    url: './sample-datasets-jp/Stacked barchart - Business status by industry division.tsv',
    delimiter: '\t',
    sourceName: 'e-Stat政府統計の総合窓口',
    sourceURL:
      'https://www.e-stat.go.jp/dbview?sid=0003239680',
    nameJp: 'エネルギー消費統計調査',
    categoryJp: '積み上げ棒グラフ',
    sample: {
      svg: './sample-charts/BarChartStacked/BarChartStacked.svg',
      project: './sample-charts/BarChartStacked/BarChartStacked.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recfj3WVJq23Ml1Kg',
  },
  {
    name: 'Japan Population statistics 2019',
    category: 'Paired Barchart',
    url: './sample-datasets-jp/Paired Barchart - Population by year-prefecture-gender.tsv',
    delimiter: '\t',
    sourceName: 'e-Govデータポータル',
    sourceURL: 'https://data.e-gov.go.jp/data/ja/dataset/mhlw_20201124_0048/resource/849cfd5d-d353-4927-b48d-0093620ed2b8?inner_span=True',
    nameJp: '年次・都道府県・性別人口統計',
    categoryJp: 'ペア棒グラフ',
    sample: {
      svg: './sample-charts/BarChartPaired/BarChartPaired.svg',
      project: './sample-charts/BarChartPaired/BarChartPaired.minichart',
    },
    descUrl: 'https://datavizproject.com/data-type/population-pyramid-2/',
  },
  {
    name: 'Tokyo mean wage per occupation',
    category: 'Beeswarm plot',
    url: './sample-datasets-jp/Beeswarm plot - Tokyo mean wages.tsv',
    delimiter: '\t',
    sourceName: '東京ハローワーク',
    sourceURL:
      'https://jsite.mhlw.go.jp/tokyo-hellowork/content/contents/001157661.pdf',
    nameJp: '東京都の職業別平均賃金',
    categoryJp: 'ビースウォーム・プロット',
    sample: {
      svg: './sample-charts/BeeswarmPlot/BeeswarmPlot.svg',
      project: './sample-charts/BeeswarmPlot/BeeswarmPlot.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recCs50U7IovyfUPb',
  },
  {
    name: 'Weather in Tokyo, 2006-2015',
    category: 'Boxplot',
    url: './sample-datasets-jp/Violin plot - Weather in Tokyo.tsv',
    delimiter: '\t',
    sourceName: '沢木技術士事務所',
    sourceURL:
      'https://pe-sawaki.com/WeatherForecast/',
    nameJp: '東京の天気（2006-2015）',
    categoryJp: '箱ひげ図',
    sample: {
      svg: './sample-charts/BoxPlot/BoxPlot.svg',
      project: './sample-charts/BoxPlot/BoxPlot.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recaWdJBEOL5w7sA3',
  },
  {
    name: 'Highest grossing movies in history',
    category: 'Bubble chart',
    url: './sample-datasets-jp/Bubble Chart - TOP 50 Groossing Movies.tsv',
    delimiter: '\t',
    sourceName: 'ウィキペディア（Wikipedia）',
    sourceURL:
      'https://ja.wikipedia.org/wiki/%E4%B8%96%E7%95%8C%E6%AD%B4%E4%BB%A3%E8%88%88%E8%A1%8C%E5%8F%8E%E5%85%A5%E4%B8%8A%E4%BD%8D%E3%81%AE%E6%98%A0%E7%94%BB%E4%B8%80%E8%A6%A7',
    nameJp: '世界歴代興行収入上位の映画一覧',
    categoryJp: 'バブル・チャート',
    sample: {
      svg: './sample-charts/BubbleChart/BubbleChart.svg',
      project: './sample-charts/BubbleChart/BubbleChart.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recymDcXvZercUz4Y',
  },
  {
    name: 'Foreign residents in Japan',
    category: 'Bumpchart',
    url: './sample-datasets-jp/Bump chart - Foreign residents in Japan.tsv',
    delimiter: '\t',
    sourceName: 'ウィキペディア（Wikipedia）',
    sourceURL:
      'https://ja.wikipedia.org/wiki/%E6%97%A5%E6%9C%AC%E3%81%AE%E5%A4%96%E5%9B%BD%E4%BA%BA',
    nameJp: '日本に居住する外国人',
    categoryJp: 'バンプ・チャート',
    sample: {
      svg: './sample-charts/BumpChart/BumpChart.svg',
      project: './sample-charts/BumpChart/BumpChart.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recLfLrUP8qX8PXYl',
  },
  {
    name: 'Estimated migration flows between macro-areas from 1960 to 1970',
    category: 'Chord diagram',
    url:
      './sample-datasets/Chord Diagram - Estimated migration flows between macro-areas.csv',
    delimiter: ',',
    sourceName: 'Gui J. Abel, via Data to Viz',
    sourceURL:
      'https://github.com/holtzy/data_to_viz/tree/master/Example_dataset',
    nameJp: 'マクロ領域間の推定移住フロー',
    categoryJp: 'コード・ダイアグラム',
    sample: {
      svg: './sample-charts/ChordDiagram/ChordDiagram.svg',
      project: './sample-charts/ChordDiagram/ChordDiagram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recjD0kGcE7G31fWt',
  },
  {
    name: 'Most populated cities per continent',
    category: 'Circle packing',
    url: './sample-datasets-jp/Circle Packing - Most populated cities.tsv',
    delimiter: '\t',
    sourceName: 'ウィキデータ（Wikidata）',
    sourceURL: 'https://w.wiki/6mXW',
    nameJp: '大陸ごとに人口が最も多い都市',
    categoryJp: 'サークル・パッキング',
    sample: {
      svg: './sample-charts/CirclePacking/CirclePacking.svg',
      project: './sample-charts/CirclePacking/CirclePacking.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=rec5LiI7pi75KCxD8',
  },
  {
    name: 'Japan Standard Industrial Classification',
    category: 'Circular dendrogram',
    url: './sample-datasets-jp/Dendrogram - Japan Standard Industrial Classification.tsv',
    delimiter: '\t',
    sourceName: '総務省',
    sourceURL: 'https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000044.html',
    nameJp: '日本標準産業分類',
    categoryJp: 'ラディアル・デンドログラム',
    sample: {
      svg: './sample-charts/CircularDendrogram/CircularDendrogram.svg',
      project: './sample-charts/CircularDendrogram/CircularDendrogram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/',
  },
  {
    name: 'Temperature in Tokyo during 1945',
    category: 'Contour plot',
    url:
      './sample-datasets-jp/Contour plot - Mean temperature in Tokyo 1945.tsv',
    delimiter: '\t',
    sourceName: '日本気象庁',
    sourceURL:
      'https://www.data.jma.go.jp/obd/stats/etrn/index.php',
    nameJp: '1945年の東京の気温',
    categoryJp: '等高線図',
    sample: {
      svg: './sample-charts/ContourPlot/ContourPlot.svg',
      project: './sample-charts/ContourPlot/ContourPlot.minichart',
    },
    descUrl: 'https://datavizproject.com/data-type/contour-plot/',
  },
  {
    name: 'Iris flowers',
    category: 'Convex hull',
    url: './sample-datasets-jp/Convex hull - Iris flowers.tsv',
    delimiter: '\t',
    sourceName: 'R. Fisher',
    sourceURL: 'https://en.wikipedia.org/wiki/Iris_flower_data_set',
    nameJp: 'アイリスの花',
    categoryJp: '凸包プロット',
    sample: {
      svg: './sample-charts/ConvexHull/ConvexHull.svg',
      project: './sample-charts/ConvexHull/ConvexHull.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/',
  },
  {
    name: 'Japan Standard Industrial Classification',
    category: 'Dendrogram',
    url: './sample-datasets-jp/Dendrogram - Japan Standard Industrial Classification.tsv',
    delimiter: '\t',
    sourceName: '総務省',
    sourceURL: 'https://www.soumu.go.jp/toukei_toukatsu/index/seido/sangyo/02toukatsu01_03000044.html',
    nameJp: '日本標準産業分類',
    categoryJp: 'リニア・デンドログラム',
    sample: {
      svg: './sample-charts/LinearDendrogram/LinearDendrogram.svg',
      project: './sample-charts/LinearDendrogram/LinearDendrogram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=rec8O9GeExlRfKLam',
  },
  {
    name: 'Japanese Prime ministers',
    category: 'Gantt chart',
    url: './sample-datasets-jp/Gantt chart - Japan PMs.tsv',
    delimiter: '\t',
    sourceName: 'ウィキデータ（Wikidata）',
    sourceURL: 'https://w.wiki/6mej',
    nameJp: '日本の首相',
    categoryJp: 'ガントチャート',
    sample: {
      svg: './sample-charts/GanttChart/GanttChart.svg',
      project: './sample-charts/GanttChart/GanttChart.minichart',
    },
    descUrl: 'https://datavizproject.com/data-type/gannt-chart/',
  },
  {
    name: 'Kobe Bryant shoots',
    category: 'Hexagonal Binning',
    url: './sample-datasets/Hexbin - basketball shots.tsv',
    delimiter: '\t',
    sourceName: 'Kaggle',
    sourceURL: 'https://www.kaggle.com/c/kobe-bryant-shot-selection/',
    nameJp: 'コービー・ブライアントのシュート',
    categoryJp: '六角ビニング',
    sample: {
      svg: './sample-charts/HexagonalBinning/HexagonalBinning.svg',
      project: './sample-charts/HexagonalBinning/HexagonalBinning.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recozztVOgKkgXfT4',
  },
  {
    name: 'JP Index of consumer prices',
    category: 'Horizon Graph',
    url: './sample-datasets-jp/Horizon Graph - bukka_chousa_kekka.tsv',
    delimiter: '\t',
    sourceName: 'e-Govデータポータル',
    sourceURL:
      'https://data.e-gov.go.jp/data/ja/dataset/caa_20191025_0009/resource/8a9b1294-234b-4eff-90b8-b2b809f74db7',
    nameJp: '消費者物価指数',
    categoryJp: '水平線グラフ',
    sample: {
      svg: './sample-charts/HorizonGraph/HorizonGraph.svg',
      project: './sample-charts/HorizonGraph/HorizonGraph.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/',
  },
  {
    name: 'Music distribution sales performance',
    category: 'Line chart',
    url: './sample-datasets-jp/Line chart - RIAJ Music format revenues.tsv',
    delimiter: '\t',
    sourceName: '日本レコード協会（RIAJ）',
    sourceURL: 'https://www.riaj.or.jp/g/data/annual/dg_t.html',
    nameJp: '音楽配信売上実績',
    categoryJp: '線グラフ',
    sample: {
      svg: './sample-charts/LineChart/LineChart.svg',
      project: './sample-charts/LineChart/LineChart.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recKRWEqQQxdfNXTR',
  },
  {
    name: 'Japan Gross Product (2006-2018)',
    category: 'Matrix plot (Heatmap)',
    url: './sample-datasets-jp/Matrix Plot - Gross Prefectural Product (Nominal).tsv',
    delimiter: '\t',
    sourceName: 'e-Govデータポータル',
    sourceURL: 'https://data.e-gov.go.jp/data/ja/dataset/cao_20210602_0017/resource/136eaffe-3f91-4b96-93fb-10d247f94a97?inner_span=True',
    nameJp: '日本県内総生産(2006-2018)',
    categoryJp: 'マトリックス図（ヒート・マップ）',
    sample: {
      svg: './sample-charts/MatrixPlot/MatrixPlot.svg',
      project: './sample-charts/MatrixPlot/MatrixPlot.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=rec3TKTDNXHAws1db',
  },
  {
    name: 'Energy consumption statistics',
    category: 'Pie chart',
    url: './sample-datasets-jp/Stacked barchart - Business status by industry division.tsv',
    delimiter: '\t',
    sourceName: 'e-Stat政府統計の総合窓口',
    sourceURL:
      'https://www.e-stat.go.jp/dbview?sid=0003239680',
    nameJp: 'エネルギー消費統計調査',
    categoryJp: '円グラフ',
    sample: {
      svg: './sample-charts/PieChart/PieChart.svg',
      project: './sample-charts/PieChart/PieChart.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recS7IoHDNmWcSZUF',
  },
  {
    name: 'Baseball Catchers statistics',
    category: 'Radar Chart',
    url: './sample-datasets-jp/Radar Chart - Baseball Catchers Ability.tsv',
    delimiter: '\t',
    sourceName: 'プロスピ選手',
    sourceURL: 'https://prospi-player.com/',
    nameJp: '実在プロ野球選手の能力',
    categoryJp: 'レーダー・チャート',
    sample: {
      svg: './sample-charts/RadarChart/RadarChart.svg',
      project: './sample-charts/RadarChart/RadarChart.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recbLnIdwTjfQc4Kd',
  },
  {
    name: 'Energy flows in Japan (2019)',
    category: 'Sankey diagram',
    url: './sample-datasets-jp/Sankey diagram - Energy flows.tsv',
    delimiter: '\t',
    sourceName: '日本エネルギーデータベース',
    sourceURL: 'https://energy-sustainability.jp/',
    nameJp: 'エネルギーフロー',
    categoryJp: 'サンキー・ダイアグラム',
    sample: {
      svg: './sample-charts/SankeyDiagram/SankeyDiagram.svg',
      project: './sample-charts/SankeyDiagram/SankeyDiagram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recygzPx3lG4mMD3O',
  },
  {
    name: 'Premier League and Serie A points',
    category: 'Slope chart',
    url: './sample-datasets-jp/Slope graph - Premier SerieA ranking.tsv',
    delimiter: '\t',
    sourceName: 'ウィキペディア（Wikipedia）',
    sourceURL: 'https://ja.wikipedia.org/wiki/%E3%83%97%E3%83%AC%E3%83%9F%E3%82%A2%E3%83%AA%E3%83%BC%E3%82%B02019-2020',
    nameJp: 'プレミアリーグとセリエAのポイント',
    categoryJp: 'スロープグラフ',
    sample: {
      svg: './sample-charts/SlopeChart/SlopeChart.svg',
      project: './sample-charts/SlopeChart/SlopeChart.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recyrVteAfsWXmQI2',
  },
  {
    name: 'Olympics Medals',
    category: 'Streamgraph',
    url: './sample-datasets/Streamgraph - Olympics Medals.tsv',
    delimiter: '\t',
    sourceName: 'D. Agrawal via Kaggle',
    sourceURL: 'https://www.kaggle.com/divyansh22/summer-olympics-medals',
    nameJp: '夏季オリンピックメダル（1976-2008）',
    categoryJp: 'ストリームグラフ',
    sample: {
      svg: './sample-charts/StreamGraph/StreamGraph.svg',
      project: './sample-charts/StreamGraph/StreamGraph.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recnqFKKxrJcFY9l3',
  },
  {
    name: 'JLPT Number of examinees (2022/7)',
    category: 'Sunburst Diagram',
    url: './sample-datasets-jp/Sunburst - JLPT Number of examinees.tsv',
    delimiter: '\t',
    sourceName: '日本語能力試験（JLPT）',
    sourceURL: 'https://www.jlpt.jp/statistics/archive/202201.html',
    nameJp: '日本語能力試験受験者数（2022/7）',
    categoryJp: 'サンバースト・チャート',
    sample: {
      svg: './sample-charts/SunburstDiagram/SunburstDiagram.svg',
      project: './sample-charts/SunburstDiagram/SunburstDiagram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recMrGt8xzVCJZYPA',
  },
  {
    name: 'Orchestras by musical instrument',
    category: 'Treemap',
    url: './sample-datasets-jp/Treemap - Orchestra.tsv',
    delimiter: '\t',
    sourceName: 'ウィキペディア（Wikipedia）',
    sourceURL:
      'https://ja.wikipedia.org/wiki/%E3%82%AA%E3%83%BC%E3%82%B1%E3%82%B9%E3%83%88%E3%83%A9',
    nameJp: '楽器別オーケストラ',
    categoryJp: 'ツリーマップ',
    sample: {
      svg: './sample-charts/Treemap/Treemap.svg',
      project: './sample-charts/Treemap/Treemap.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recnliR602l2HD1e5',
  },
  {
    name: 'Products details with images',
    category: 'Treemap Images',
    url: './sample-datasets-jp/Treemap Images - Products details with images.csv',
    delimiter: ',',
    sourceName: 'Googleスプレッドシート',
    sourceURL:
      'https://docs.google.com/spreadsheets/d/1PtPPQ6IQQhYuHp2k4hBlY6siYkMB07DAuqPC_tN9CO0/edit#gid=956419822',
    nameJp: '画像付きの製品詳細',
    categoryJp: 'イメージ・ツリーマップ',
    sample: {
      svg: './sample-charts/TreemapImages/TreemapImages.svg',
      project: './sample-charts/TreemapImages/TreemapImages.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/',
  },
  {
    name: 'Weather in Tokyo, 2006-2015',
    category: 'Violin plot',
    url: './sample-datasets-jp/Violin plot - Weather in Tokyo.tsv',
    delimiter: '\t',
    sourceName: '沢木技術士事務所',
    sourceURL:
      'https://pe-sawaki.com/WeatherForecast/',
    nameJp: '東京の天気（2006-2015）',
    categoryJp: 'バイオリン図',
    sample: {
      svg: './sample-charts/ViolinPlot/ViolinPlot.svg',
      project: './sample-charts/ViolinPlot/ViolinPlot.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recMwjRJBIwun7gR0',
  },
  {
    name: 'Kobe Bryant shoots',
    category: 'Voronoi diagram',
    url: './sample-datasets/Hexbin - basketball shots.tsv',
    delimiter: '\t',
    sourceName: 'Kaggle',
    sourceURL: 'https://www.kaggle.com/c/kobe-bryant-shot-selection/',
    nameJp: 'コービー・ブライアントのシュート',
    categoryJp: 'ボロノイ図',
    sample: {
      svg: './sample-charts/VoronoiDiagram/VoronoiDiagram.svg',
      project: './sample-charts/VoronoiDiagram/VoronoiDiagram.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/',
  },
  {
    name: 'Orchestras by musical instrument',
    category: 'Voronoi Treemap',
    url: './sample-datasets-jp/Treemap - Orchestra.tsv',
    delimiter: '\t',
    sourceName: 'ウィキペディア（Wikipedia）',
    sourceURL:
      'https://ja.wikipedia.org/wiki/%E3%82%AA%E3%83%BC%E3%82%B1%E3%82%B9%E3%83%88%E3%83%A9',
    nameJp: '楽器別オーケストラ',
    categoryJp: 'ボロノイ・ツリーマップ',
    sample: {
      svg: './sample-charts/VoronoiTreemap/VoronoiTreemap.svg',
      project: './sample-charts/VoronoiTreemap/VoronoiTreemap.minichart',
    },
    descUrl: 'https://charts.visualizing.jp/',
  },

  // まだ使わない
  // {
  //   name: 'Iris flowers',
  //   category: 'Parallel Coordinates',
  //   url: './sample-datasets-jp/Convex hull - Iris flowers.tsv',
  //   delimiter: '\t',
  //   sourceName: 'R. Fisher',
  //   sourceURL: 'https://en.wikipedia.org/wiki/Iris_flower_data_set',
  //   nameJp: 'アイリスの花',
  //   categoryJp: 'パラレル・コーディネイト',
  //   descUrl: 'https://charts.visualizing.jp/item-detail?recordId=recO4XlG2O3bvQG5h',
  // },

  // {
  //   name: '',
  //   category: '',
  //   url: './sample-datasets/',
  //   delimiter: '\t',
  //   sourceName: '',
  //   sourceURL: '',
  // },
]
export default function DataSamples({ onSampleReady, onProjectSelected, setLoadingError }) {
  const selectSample = async (sample) => {
    const projectUrl = sample.sample.project
    let response
    try {
      response = await fetch(projectUrl)
    } catch (e) {
      setLoadingError('読み込みエラー：' + e.message)
      return
    }
    const projectJson = await response.text()
    try {
      const project = deserializeProject(projectJson, charts)
      setLoadingError(null)
      onProjectSelected(project)
    } catch (e) {
      setLoadingError(e.message)
    }
  }
  return (
    <Row>
      {samplesList
        // sort by category name
        .sort((a, b) => a.category.localeCompare(b.category))
        .map((d, i) => {
          return (
            <Col xs={6} lg={4} xl={3} key={i} style={{ marginBottom: 15, height: 320 }}>
              <Card className="cursor-pointer h-100">
                <Card.Body
                  onClick={() => {
                    selectSample(d)
                  }}
                  className="d-flex flex-column"
                >
                  <Card.Title className="">
                    <h2 className="">{d.category}</h2>
                    <h2 className="">{d.categoryJp}</h2>
                    <h4 className="m-0">{d.nameJp??d.name}</h4>
                  </Card.Title>
                  <div
                    className={`h-100 w-100 cursor-pointer ${styles.thumbnail}`}
                    style={{ backgroundImage: `url("${d.sample.svg}")` }}
                  ></div>
                </Card.Body>
                <a
                  href={d.sourceURL}
                  className={[styles['dataset-source']].join(' ')}
                  target="_blank"
                  rel="noreferrer"
                >
                  Source: {d.sourceName}
                </a>
              </Card>
            </Col>
          )
        })}
    </Row>
  )
}
