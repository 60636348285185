import { get } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import {
  BsArrowCounterclockwise,
  BsArrowRepeat,
  BsClipboard,
  BsFolder,
  BsSearch,
  BsUpload,
} from 'react-icons/bs'
import { AiOutlineExperiment } from 'react-icons/ai'
import { DATA_LOADER_MODE } from '../../hooks/useDataLoader'
import DataGrid from '../DataGrid/DataGrid'
import DataSamples from '../DataSamples/DataSamples'
import JsonViewer from '../JsonViewer'
import ParsingOptions from '../ParsingOptions'
import styles from './DataLoader.module.scss'
import LoadProject from './loaders/LoadProject'
import Paste from './loaders/Paste'
import UploadFile from './loaders/UploadFile'
import UrlFetch from './loaders/UrlFetch'
import Loading from './loading'
import WarningMessage from '../WarningMessage'
import DataMismatchModal from './DataMismatchModal'
import { tsvFormat } from 'd3-dsv'
import { CopyToClipboardButton } from '../CopyToClipboardButton'
import GallerySelector from '../GallerySelector'
import { GrGallery, GrUserAdmin } from 'react-icons/gr'
import { collection, getDocs, doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { FIREBASE } from '../../constants'
import { getFingerPrint, getLatestFingerPrint } from '../../storage'
import { MdAdd } from 'react-icons/md'
import _ from 'lodash'

const getCloudCharts = async () => {
  const querySnapshot = await getDocs(collection(db, FIREBASE['COLLECTION']))
  const cloudCharts = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }))
  return [].concat(...cloudCharts)
}

function DataLoader({
  userInput,
  setUserInput,
  userData,
  userDataType,
  parseError,
  unstackedColumns,
  separator,
  setSeparator,
  thousandsSeparator,
  setThousandsSeparator,
  decimalsSeparator,
  setDecimalsSeparator,
  locale,
  setLocale,
  stackDimension,
  dataSource,
  data,
  loading,
  coerceTypes,
  loadSample,
  handleInlineEdit,
  handleStackOperation,
  setJsonData,
  resetDataLoader,
  dataLoaderMode,
  startDataReplace,
  cancelDataReplace,
  commitDataReplace,
  replaceRequiresConfirmation,
  hydrateFromProject,
}) {
  const [loadingError, setLoadingError] = useState()
  const [initialOptionState, setInitialOptionState] = useState(null)
  const [availableCharts, setAvailableCharts] = useState([])
  const [myCharts, setMyCharts] = useState([])

  const refreshPublicCharts = (isSelf) => {
    getCloudCharts().then(charts => {
      console.log(charts)
      if (!isSelf) {
        setAvailableCharts(charts.filter(chart => chart.public === true && chart.isActive === true))
      } else {
        setMyCharts(charts.filter(chart => _.indexOf(getFingerPrint(), chart.mcid) !== -1 && chart.isActive === true))
      }
    })
  }

  useEffect(() => {
    refreshPublicCharts(true)
    refreshPublicCharts(false)
  }, [])

  const removeGalleryChart = useCallback(async (chart) => {
    // await deleteDoc(doc(db, FIREBASE['COLLECTION'], id))
    chart.isActive = false
    chart.updateTimestamp = new Date().getTime()
    await setDoc(doc(db, FIREBASE['COLLECTION'], chart.id), chart)
    refreshPublicCharts(true)
    refreshPublicCharts(false)
  }, [])

  const thumbupGalleryChart = useCallback(async (chart) => {
    chart.statistics.thumbup += 1
    chart.statistics.thumbupBy += getLatestFingerPrint() + ','
    await setDoc(doc(db, FIREBASE['COLLECTION'], chart.id), chart)
    refreshPublicCharts(false)
  }, [])

  const clickGalleryChart = useCallback(async (chart) => {
    chart.statistics.clicked += 1
    await setDoc(doc(db, FIREBASE['COLLECTION'], chart.id), chart)
    refreshPublicCharts(true)
    refreshPublicCharts(false)
  }, [])

  const useGalleryChart = useCallback(async (chart) => {
    chart.statistics.used += 1
    await setDoc(doc(db, FIREBASE['COLLECTION'], chart.id), chart)
  }, [])

  const publicGalleryChart = useCallback(async (chart) => {
    chart.public = !chart.public
    chart.updateTimestamp = new Date().getTime()
    await setDoc(doc(db, FIREBASE['COLLECTION'], chart.id), chart)
    refreshPublicCharts(true)
    refreshPublicCharts(false)
  }, [])

  const options = [
    {
      id: 'new',
      name: 'チャートを作成する',
      type: 'group',
      icon: MdAdd,
      options: [
        {
          id: 'paste',
          name: 'データペースト',
          loader: (
            <Paste
              userInput={userInput}
              setUserInput={(rawInput) => setUserInput(rawInput, { type: 'paste' })}
              setLoadingError={setLoadingError}
            />
          ),
          message:
            '他のアプリケーションまたはWebサイトからデータをコピペします。表形式 (CSV、TSV、DSV) またはJSONデータを使用できます。',
          icon: BsClipboard,
          allowedForReplace: true,
        },
        {
          id: 'upload',
          name: 'データアップロード',
          loader: (
            <UploadFile
              userInput={userInput}
              setUserInput={(rawInput) =>
                setUserInput(rawInput, { type: 'upload' })
              }
              setLoadingError={setLoadingError}
            />
          ),
          message: '表形式 (CSV、TSV、DSV) またはJSONデータを使用できます。',
          icon: BsUpload,
          allowedForReplace: true,
        },
        {
          id: 'url',
          name: 'URLからデータロード',
          message:
            'データ (パブリックデータファイル、パブリックAPIなど) を指すWebアドレス (URL) を入力します。 サーバーがCORS対応であることを確認してください。',
          loader: (
            <UrlFetch
              userInput={userInput}
              setUserInput={(rawInput, source) => setUserInput(rawInput, source)}
              setLoadingError={setLoadingError}
              initialState={
                initialOptionState?.type === 'url' ? initialOptionState : null
              }
            />
          ),
          icon: BsSearch,
          disabled: false,
          allowedForReplace: true,
        },
        // {
        //   id: 'sparql',
        //   name: 'SPARQLクエリ',
        //   message: 'SPARQLクエリからデータをロードします。',
        //   loader: (
        //     <SparqlFetch
        //       userInput={userInput}
        //       setUserInput={(rawInput, source) => setUserInput(rawInput, source)}
        //       setLoadingError={setLoadingError}
        //       initialState={
        //         initialOptionState?.type === 'sparql' ? initialOptionState : null
        //       }
        //     />
        //   ),
        //   icon: BsCloud,
        //   disabled: false,
        //   allowedForReplace: true,
        // },
        {
          id: 'project',
          name: '既存プロジェクト参照',
          message: '.minichartプロジェクトからデータをロードします。',
          loader: (
            <LoadProject
              onProjectSelected={hydrateFromProject}
              setLoadingError={setLoadingError}
            />
          ),
          icon: BsFolder,
          allowedForReplace: false,
        },
        {
          id: 'sample',
          name: 'サンプルチャート参照',
          message: '',
          loader: (
            <DataSamples
              onSampleReady={loadSample}
              onProjectSelected={hydrateFromProject}
              setLoadingError={setLoadingError}
            />
          ),
          icon: AiOutlineExperiment,
          allowedForReplace: true,
        },
      ],
    },
    {
      id: 'gallery',
      name: 'チャートギャラリーを参照する',
      message: '公開されたチャートからプロジェクトをロードします。',
      loader: (
        <GallerySelector
          isSelfCharts={false}
          availableCharts={availableCharts}
          onProjectSelected={hydrateFromProject}
          setLoadingError={setLoadingError}
          onUseGalleryChart={useGalleryChart}
          onThumbupGalleryChart={thumbupGalleryChart}
          onClickGalleryChart={clickGalleryChart}
        />
      ),
      icon: GrGallery,
      allowedForReplace: false,
    },
    {
      id: 'mycharts',
      name: '個人チャートを参照する',
      message: '',
      loader: (
        <GallerySelector
          isSelfCharts={true}
          availableCharts={myCharts}
          onProjectSelected={hydrateFromProject}
          setLoadingError={setLoadingError}
          onUseGalleryChart={useGalleryChart}
          onPublicGalleryChart={publicGalleryChart}
          onClickGalleryChart={clickGalleryChart}
          onDeleteGalleryChart={removeGalleryChart}
        />
      ),
      icon: GrUserAdmin,
      allowedForReplace: false,
    },
  ]

  const findOption = (optionIndex) => {
    if (_.isNumber(optionIndex)) {
      return options[_.toInteger(optionIndex)]
    } else {
      // groupid-index
      const parts = _.split(optionIndex, '-')
      const group = _.find(options, option => option.id === parts[0])
      if (group) {
        let subOption = group.options[_.toInteger(parts[1])]
        subOption.group = group.id
        return subOption
      }
      // if cannot find option, show the last option -- "mycharts"
      return _.find(options, option => option.id === 'mycharts')
    }
  }
  const [optionIndex, setOptionIndex] = useState('new-0')
  const selectedOption = findOption(optionIndex)

  const findOptionIndexByDatasource = (datasource) => {
    const loaderId = 'new'
    const loader = _.find(options, option => option.id === loaderId)
    let optionIndex = `${loaderId}-0`
    for (let i = 0; i < loader.options.length; i++) {
      if (loader.options[i].id === datasource?.type) {
        optionIndex = `${loaderId}-${i}`
      }
    }
    return optionIndex
  }

  let mainContent
  if (userData && data) {
    mainContent = (
      <DataGrid
        userDataset={userData}
        dataset={data.dataset}
        errors={data.errors}
        dataTypes={data.dataTypes}
        coerceTypes={coerceTypes}
        onDataUpdate={handleInlineEdit}
      />
    )
  } else if (userDataType === 'json' && userData === null) {
    mainContent = (
      <JsonViewer
        context={JSON.parse(userInput)}
        selectFilter={(ctx) => Array.isArray(ctx)}
        onSelect={(ctx, path) => {
          setJsonData(ctx, path)
        }}
      />
    )
  } else if (loading && !data) {
    mainContent = <Loading />
  } else {
    mainContent = (
      <>
        {selectedOption.loader}
        <p className="mt-3">
          {selectedOption.message}
          {/*<a
            href="https://rawgraphs.io/learning"
            target="_blank"
            rel="noopener noreferrer"
          >
            Check out our guides
          </a>*/}
        </p>
      </>
    )
  }

  // #TODO: memoize/move to component?
  function parsingErrors(data) {
    const errors = get(data, 'errors', [])
    const successRows = data.dataset.length - errors.length
    const row = errors[0].row + 1
    const column = Object.keys(errors[0].error)[0]
    return (
      <span>
        すみません、<span className="font-weight-bold">行 {row}</span> の
        列 <span className="font-weight-bold">{column}</span>を確認してください。{' '}
        {errors.length === 2 && (
          <>
            {' '}
            <span className="font-weight-bold">行 {errors[1].row + 1}</span> に別の問題があります。{' '}
          </>
        )}
        {errors.length > 2 && (
          <>
            {' '}
            あと{' '}
            <span className="font-weight-bold">{errors.length - 1}</span> 行に
            問題があります。{' '}
          </>
        )}
        {successRows > 0 && (
          <>
            残りの{' '}
            <span className="font-weight-bold">
              {successRows} 行
            </span>{' '}
            は問題ないようです。
          </>
        )}
      </span>
    )
  }

  const reloadRAW = useCallback(() => {
    window.location.replace(window.location.pathname)
  }, [])

  const copyToClipboardButton = !!userData ? (
    <CopyToClipboardButton content={tsvFormat(userData)} />
  ) : null

  return (
    <>
      <Row>
        {!userData && (
          <Col
            xs={3}
            lg={2}
            className="d-flex flex-column justify-content-start pl-3 pr-0 options"
          >
            {options
              .filter((opt) => {
                return (
                  dataLoaderMode !== DATA_LOADER_MODE.REPLACE ||
                  opt.allowedForReplace
                )
              })
              .map((d, i) => {
                const classnames = [
                  'w-100',
                  'd-flex',
                  'align-items-center',
                  'user-select-none',
                  'cursor-pointer',
                  styles['loading-option'],
                  d.disabled ? styles['disabled'] : null,
                  (d.id === selectedOption.id || d.id === selectedOption.group) && !userDataType
                    ? styles.active
                    : null,
                  userDataType ? styles.disabled : null,
                ]
                  .filter((c) => c !== null)
                  .join(' ')
                if (d.type === 'group') {
                  return (
                    <div
                      key={d.id}
                      className={`${classnames} option`}
                    >
                      <d.icon className="w-25" />
                      <Dropdown className="d-inline-block">
                        <Dropdown.Toggle
                          variant="white"
                          className={`truncate-160px ${d.id === selectedOption.group ? styles.activeDropdown : styles.dropdown}`}
                          disabled={d.options.length === 0}
                        >
                          <h4 className="m-0 d-inline-block">{d.name}</h4>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {d.options
                            .filter((subOpt) => {
                              return (
                                dataLoaderMode !== DATA_LOADER_MODE.REPLACE ||
                                subOpt.allowedForReplace
                              )
                            })
                            .map((sub, j) => {
                              return (
                                <Dropdown.Item
                                  key={d.id + '-' + j}
                                  onSelect={() => setOptionIndex(d.id + '-' + j)}
                                  className={`${styles.optionItem} ${optionIndex === (d.id + '-' + j) ? styles.optionItemActive : ''}`}
                                >
                                  <sub.icon className="w-25" />
                                  <h4 className="m-0 d-inline-block">{sub.name}</h4>
                                </Dropdown.Item>
                              )
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )
                } else {
                  return (
                    <div
                      key={d.id}
                      className={classnames}
                      onClick={() => {
                        setOptionIndex(i)
                      }}
                    >
                      <d.icon className="w-25" />
                      <h4 className="m-0 d-inline-block">{d.name}</h4>
                    </div>
                  )
                }
              })}

            {dataLoaderMode === DATA_LOADER_MODE.REPLACE && (
              <>
                <div className="divider mb-3 mt-0" />
                <div
                  className={`w-100 mb-2 d-flex justify-content-center align-items-center ${styles['start-over']} user-select-none cursor-pointer`}
                  onClick={reloadRAW}
                >
                  <BsArrowRepeat className="mr-2" />
                  <h4 className="m-0 d-inline-block">{'リセット'}</h4>
                </div>

                <div
                  className={`w-100 d-flex justify-content-center align-items-center ${styles['start-over']} ${styles['cancel']} user-select-none cursor-pointer mb-3`}
                  onClick={() => {
                    cancelDataReplace()
                  }}
                >
                  <h4 className="m-0 d-inline-block">{'キャンセル'}</h4>
                </div>
              </>
            )}
          </Col>
        )}
        {userData && (
          <Col
            xs={3}
            lg={2}
            className="d-flex flex-column justify-content-start pl-3 pr-0 options"
          >
            <ParsingOptions
              locale={locale}
              setLocale={setLocale}
              separator={separator}
              setSeparator={setSeparator}
              thousandsSeparator={thousandsSeparator}
              setThousandsSeparator={setThousandsSeparator}
              decimalsSeparator={decimalsSeparator}
              setDecimalsSeparator={setDecimalsSeparator}
              dimensions={data ? unstackedColumns || data.dataTypes : []}
              stackDimension={stackDimension}
              setStackDimension={handleStackOperation}
              userDataType={userDataType}
              dataSource={dataSource}
              onDataRefreshed={(rawInput) => setUserInput(rawInput, dataSource)}
            />

            <div className="divider mb-3 mt-0" />

            <div
              className={`w-100 mb-2 d-flex justify-content-center align-items-center ${styles['start-over']} user-select-none cursor-pointer`}
              onClick={reloadRAW}
            >
              <BsArrowRepeat className="mr-2" />
              <h4 className="m-0 d-inline-block">{'リセット'}</h4>
            </div>

            <div
              className={`w-100 d-flex justify-content-center align-items-center ${styles['start-over']} user-select-none cursor-pointer`}
              onClick={() => {
                setInitialOptionState(dataSource)
                setOptionIndex(findOptionIndexByDatasource(dataSource))
                startDataReplace()
              }}
            >
              <BsArrowCounterclockwise className="mr-2" />
              <h4 className="m-0 d-inline-block">{'データを変更する'}</h4>
            </div>
          </Col>
        )}
        <Col>
          <Row className="h-100">
            <Col className="h-100">
              {mainContent}

              {data && !parseError && get(data, 'errors', []).length === 0 && (
                <WarningMessage
                  variant="success"
                  message={
                    <span>
                      <span className="font-weight-bold">
                        {data.dataset.length} 行
                      </span>{' '}
                      (
                      {data.dataset.length * Object.keys(data.dataTypes).length}{' '}
                      セル) が正常に解析されました。グラフを選択できるようになりました。
                    </span>
                  }
                  action={copyToClipboardButton}
                />
              )}

              {parseError && (
                <WarningMessage
                  variant="danger"
                  message={parseError}
                  action={copyToClipboardButton}
                />
              )}

              {get(data, 'errors', []).length > 0 && (
                <WarningMessage
                  variant="warning"
                  message={parsingErrors(data)}
                  action={copyToClipboardButton}
                />
              )}

              {loadingError && (
                <WarningMessage
                  variant="danger"
                  message={loadingError}
                  action={copyToClipboardButton}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {replaceRequiresConfirmation && (
        <DataMismatchModal
          replaceRequiresConfirmation={replaceRequiresConfirmation}
          commitDataReplace={commitDataReplace}
          cancelDataReplace={cancelDataReplace}
        />
      )}
    </>
  )
}

export default React.memo(DataLoader)
