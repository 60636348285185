import React, { useCallback, useState } from 'react'
import { InputGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import { onChartDeployed, onChartExported } from '../../gaEvents'
import * as Minio from 'minio'
import { USE_MINIO, OSS, FIREBASE, URL_SHORTENER } from '../../constants'
import { CopyToClipboardButton } from '../CopyToClipboardButton'
import { samplesList } from '../DataSamples/DataSamples'
import { db, storage } from '../../firebase'
import { collection, addDoc, setDoc, doc } from 'firebase/firestore'
import { filter } from 'lodash'
import { getLatestFingerPrint } from '../../storage'
import { getDomain } from '../../utils'
import { ref, uploadString, getDownloadURL } from "firebase/storage"
import axios from 'axios'

function makeUnique(base) {
  const now = new Date().getTime()
  let random = Math.floor(Math.random() * 100000)
  // zero pad random
  random = '' + random
  while (random.length < 5) {
    random = '0' + random
  }
  return base + now + random
}

function downloadBlob(url, filename) {
  // Create a new anchor element
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'
  a.click()
  return a
}

const MINIO_DEF = OSS['MINIO']
const minioClient = new Minio.Client({
  endPoint: MINIO_DEF['endPoint'],
  port: MINIO_DEF['port'],
  useSSL: MINIO_DEF['useSSL'],
  accessKey: MINIO_DEF['accessKey'],
  secretKey: MINIO_DEF['secretKey'],
})

export default function ShareEmbed({ rawViz, exportProject, setShareError, chartId }) {
  const exportFormats = ['svg', 'png', 'jpg', 'minichart']

  const [currentFormat, setCurrentFormat] = useState('svg')
  const [currentFile, setCurrentFile] = useState('mc-viz')
  const [currentUrl, setCurrentUrl] = useState()
  const [currentEmbed, setCurrentEmbed] = useState()
  const [chartDesc, setChartDesc] = useState()
  const [chartUrl, setChartUrl] = useState()

  const changeFormat = (format) => {
    setCurrentFormat(format)
    if (format === 'minichart') {
      setCurrentEmbed('')
    }
  }

  const addChart = useCallback(
    async (chart) => {
      let fbChartId
      try {
        if (chartId) {
          await setDoc(doc(db, FIREBASE['COLLECTION'], chartId), chart)
          fbChartId = chartId
        } else {
          const docRef = await addDoc(collection(db, FIREBASE['COLLECTION']), chart)
          fbChartId = docRef.id
        }
      } catch (err) {
        console.log(err)
        if (err.name === 'FirebaseError') {
          if (err.message.startsWith('Request payload size exceeds the limit')) {
            setShareError('申し訳ございません、データサイズが制限を超えていますので、データセットを10M以下で調整してください。')
          }
        } else {
          setShareError('申し訳ございません、クラウド保管にエラーは発生しました。再度試してください。')
        }
        return false
      }
      setChartUrl(`${getDomain()}/${fbChartId}`)
      return true
    }, [
      chartId,
      setShareError,
    ])

  const publicChart = useCallback(
    async (filenamePrefix, chart, isPublic) => {
      const projectFilename = `${filenamePrefix}.minichart`
      const project = await exportProject()
      const projectString = JSON.stringify(project)
      if (USE_MINIO) {
        minioClient.putObject(MINIO_DEF['bucketNamePublic'], projectFilename, projectString, async function(err, etag) {
          if (err) {
            setPublicFinished(false)
            return console.log(err)
          }
          chart.sample.project = `${MINIO_DEF['useSSL'] ? 'https://' : 'http://'}${MINIO_DEF['endPoint']}:${MINIO_DEF['port']}/${MINIO_DEF['bucketNamePublic']}/${projectFilename}`
          const result = await addChart(chart)
          if (result && isPublic) setPublicFinished(true)
        })
      } else {
        const docRef = ref(storage, `${FIREBASE['bucketNamePublic']}/${projectFilename}`)
        uploadString(docRef, projectString).then((snapshot) => {
          getDownloadURL(docRef).then(async (url) => {
            if (URL_SHORTENER['USED']) {
              axios
                .post(`${URL_SHORTENER['DOMAIN']}/short`, {origUrl: url})
                .then(async (res) => {
                  chart.sample.project = url
                  chart.sample.project_s = res.data.shortUrl
                  chart.sample.file = projectFilename
                  const result = await addChart(chart)
                  if (result && isPublic) setPublicFinished(true)
                })
                .catch(err => {
                  console.log(err.message);
                });
            } else {
              chart.sample.project = url
              chart.sample.file = projectFilename
              const result = await addChart(chart)
              if (result && isPublic) setPublicFinished(true)
            }
          })
        })
      }
    },
    [
      exportProject,
      addChart,
    ],
  )

  const doUpload = useCallback(
    (filename, stringOrBuffer, dataUrl, chart) => {
      if (USE_MINIO) {
        minioClient.putObject(MINIO_DEF['bucketName'], filename, stringOrBuffer, function(err, etag) {
          if (err) {
            return console.log(err)
          }
          minioClient.presignedUrl('GET', MINIO_DEF['bucketName'], filename, MINIO_DEF['expiry'], function(err, presignedUrl) {
            if (err) return console.log(err)

            setCurrentUrl(presignedUrl)

            // const iframeDom = `<iframe title="Mini Chart Visualization" aria-label="${rawViz._chartImplementation.metadata.name}" id="${filename}" src="${presignedUrl}" scrolling="no" frameborder="0" style="border: none;" width="100%" height="100%" data-external="1"></iframe>`
            // setCurrentIframe(iframeDom)
            switch (currentFormat) {
              case 'svg':
                setCurrentEmbed(`<div>${stringOrBuffer}</div>`)
                break
              case 'png':
              case 'jpg':
                setCurrentEmbed(`<div><img src="${dataUrl}" alt="${filename}" title="${filename}"/></div>`)
                break
              case 'minichart':
                setCurrentEmbed('')
                break
              default:
                break
            }

            // privateチャートとしてクラウドに保管する
            let parts = filename.split('.')
            parts.pop()
            publicChart(parts.join('.'), chart, false)
          })
          // return console.log('success', etag)
        })
      } else {
        const docRef = ref(storage, `${FIREBASE['bucketNamePublic']}/${filename}`)
        switch (currentFormat) {
          case 'svg':
            uploadString(docRef, stringOrBuffer).then((snapshot) => {
              setCurrentEmbed(`<div>${stringOrBuffer}</div>`)
              getDownloadURL(docRef).then((url) => {
                if (URL_SHORTENER['USED']) {
                  axios
                    .post(`${URL_SHORTENER['DOMAIN']}/short`, {origUrl: url})
                    .then(res => {
                      console.log(res.data);
                      setCurrentUrl(res.data.shortUrl)
                    })
                    .catch(err => {
                      console.log(err.message);
                    });
                } else {
                  setCurrentUrl(url)
                }
              })
            })
            break
          case 'png':
          case 'jpg':
            uploadString(docRef, dataUrl, 'data_url').then((snapshot) => {
              setCurrentEmbed(`<div><img src="${dataUrl}" alt="${filename}" title="${filename}"/></div>`)
              getDownloadURL(docRef).then((url) => {
                if (URL_SHORTENER['USED']) {
                  axios
                    .post(`${URL_SHORTENER['DOMAIN']}/short`, {origUrl: url})
                    .then(res => {
                      console.log(res.data);
                      setCurrentUrl(res.data.shortUrl)
                    })
                    .catch(err => {
                      console.log(err.message);
                    });
                } else {
                  setCurrentUrl(url)
                }
              })
            })
            break
          case 'minichart':
            uploadString(docRef, stringOrBuffer).then((snapshot) => {
              setCurrentEmbed('')
              getDownloadURL(docRef).then((url) => {
                if (URL_SHORTENER['USED']) {
                  axios
                    .post(`${URL_SHORTENER['DOMAIN']}/short`, {origUrl: url})
                    .then(res => {
                      console.log(res.data);
                      setCurrentUrl(res.data.shortUrl)
                    })
                    .catch(err => {
                      console.log(err.message);
                    });
                } else {
                  setCurrentUrl(url)
                }
              })
            })
            break
          default:
            break
        }
        // privateチャートとしてクラウドに保管する
        let parts = filename.split('.')
        parts.pop()
        publicChart(parts.join('.'), chart, false)
      }
    }, [currentFormat, publicChart])

  const deploySvg = useCallback(
    (filename, chart) => {
      const svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild,
      )
      doUpload(filename, svgString, null, chart)
    },
    [
      rawViz,
      doUpload,
    ],
  )

  const deployImage = useCallback(
    (format, filename, chart) => {
      var svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild,
      )
      var DOMURL = window.URL || window.webkitURL || window
      var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svg)
      var canvas = document.createElement('canvas')
      canvas.height = rawViz._node.firstChild.clientHeight
      canvas.width = rawViz._node.firstChild.clientWidth
      var ctx = canvas.getContext('2d')
      var img = new Image()
      img.onload = function() {
        ctx.drawImage(img, 0, 0)
        var dataUrl = canvas.toDataURL(format)
        var buf = Buffer.from(dataUrl.replace(/^data:image\/\w+;base64,/, ''), 'base64')
        doUpload(filename, buf, dataUrl, chart)
        DOMURL.revokeObjectURL(svg)
      }
      img.src = url
    },
    [
      rawViz,
      doUpload,
    ],
  )

  const deployProject = useCallback(
    async (filename, chart) => {
      const project = await exportProject()
      const projectString = JSON.stringify(project)
      doUpload(filename, projectString, null, chart)
    },
    [
      exportProject,
      doUpload,
    ],
  )

  const downloadSvg = useCallback(
    (filename) => {
      var svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild,
      )
      var DOMURL = window.URL || window.webkitURL || window
      var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svg)
      downloadBlob(url, filename)
      DOMURL.revokeObjectURL(svg)
    },
    [rawViz],
  )

  const downloadImage = useCallback(
    (format, filename) => {
      var svgString = new XMLSerializer().serializeToString(
        rawViz._node.firstChild,
      )
      var DOMURL = window.URL || window.webkitURL || window
      var svg = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
      var url = DOMURL.createObjectURL(svg)
      var canvas = document.createElement('canvas')
      canvas.height = rawViz._node.firstChild.clientHeight
      canvas.width = rawViz._node.firstChild.clientWidth
      var ctx = canvas.getContext('2d')
      var img = new Image()
      img.onload = function() {
        ctx.drawImage(img, 0, 0)
        var dataUrl = canvas.toDataURL(format)
        downloadBlob(dataUrl, filename)
        DOMURL.revokeObjectURL(svg)
      }
      img.src = url
    },
    [rawViz],
  )

  const downloadProject = useCallback(
    async (filename) => {
      const project = await exportProject()
      const str = JSON.stringify(project)
      const blob = new Blob([str], { type: 'application/json' })
      const DOMURL = window.URL || window.webkitURL || window
      const url = DOMURL.createObjectURL(blob)
      downloadBlob(url, filename)
      DOMURL.revokeObjectURL(url)
    },
    [exportProject],
  )

  const getCurrentChartInfo = useCallback(
    (isPublic) => {
      const categoryJp = rawViz._chartImplementation.metadata.name
      const category = findCategory(categoryJp)
      let svg = new XMLSerializer().serializeToString(
        rawViz._node.firstChild,
      )
      svg = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svg)))
      const now = new Date().getTime()
      return {
        mcid: getLatestFingerPrint(),
        cloud: true,
        public: isPublic,
        category: category,
        categoryJp: categoryJp,
        title: rawViz._visualOptions.title ?? null,
        desc: chartDesc,
        sample: {
          svg: svg,
          project: null,
          width: rawViz._visualOptions.width,
          height: rawViz._visualOptions.height,
        },
        statistics: {
          clicked: 0,
          thumbup: 0,
          thumbupBy: '',
          used: 0,
        },
        timestamp: now,
        isActive: true,
        updateTimestamp: now,
      }
    },
    [
      rawViz,
      chartDesc,
    ],
  )

  const deployViz = useCallback(() => {
    setShareError(null)
    if (!chartDesc ?? ''.trim()) {
      setShareError('チャート概要は、データソースまたは概要説明を入力してください。')
      return false
    }
    const chart = getCurrentChartInfo(false)
    const uniqueFile = makeUnique(currentFile)
    switch (currentFormat) {
      case 'svg':
        deploySvg(`${uniqueFile}.svg`, chart)
        break
      case 'png':
        deployImage('image/png', `${uniqueFile}.png`, chart)
        break
      case 'jpg':
        deployImage('image/jpeg', `${uniqueFile}.jpg`, chart)
        break
      case 'minichart':
        deployProject(`${uniqueFile}.minichart`, chart)
        break
      default:
        break
    }
    // TODO: Make a getter for _chartImplementation
    onChartDeployed(rawViz._chartImplementation.metadata, currentFormat)
  }, [
    currentFile,
    currentFormat,
    deployImage,
    deploySvg,
    deployProject,
    rawViz,
    getCurrentChartInfo,
    chartDesc,
    setShareError,
  ])

  const chartUrlCopyToClipboardButton = !!chartUrl ? (
    <CopyToClipboardButton content={chartUrl} />
  ) : null

  const urlCopyToClipboardButton = !!currentUrl ? (
    <CopyToClipboardButton content={currentUrl} />
  ) : null

  const embedCopyToClipboardButton = (!!currentEmbed && currentFormat !== 'minichart') ? (
    <CopyToClipboardButton content={currentEmbed} />
  ) : null

  const downloadViz = useCallback(() => {
    setShareError(null)
    switch (currentFormat) {
      case 'svg':
        downloadSvg(`${currentFile}.svg`)
        break
      case 'png':
        downloadImage('image/png', `${currentFile}.png`)
        break
      case 'jpg':
        downloadImage('image/jpeg', `${currentFile}.jpg`)
        break
      case 'minichart':
        downloadProject(`${currentFile}.minichart`)
        break
      default:
        break
    }
    // TODO: Make a getter for _chartImplementation
    onChartExported(rawViz._chartImplementation.metadata, currentFormat)
  }, [
    currentFile,
    currentFormat,
    downloadImage,
    downloadProject,
    downloadSvg,
    rawViz,
    setShareError,
  ])

  const [publicFinished, setPublicFinished] = useState(false)
  const findCategory = (categoryJp) => {
    const sampleChart = filter(samplesList, sample => {
      return sample.categoryJp === categoryJp
    })[0]
    return sampleChart.category
  }
  const publicViz = useCallback(() => {
    setShareError(null)
    if (!chartDesc ?? ''.trim()) {
      setShareError('チャート概要は、データソースまたは概要説明を入力してください。')
      return false
    }
    const chart = getCurrentChartInfo(true)
    const uniqueFile = makeUnique(currentFile)
    publicChart(`${uniqueFile}`, chart, true)
  }, [
    currentFile,
    publicChart,
    getCurrentChartInfo,
    chartDesc,
    setShareError,
  ])

  return (
    <>
      <div className="row">
        <div className="col col-sm-3">
          <InputGroup className="mb-3 raw-input-group">
            <input
              type="text"
              className="form-control text-field"
              value={currentFile}
              onChange={(e) => setCurrentFile(e.target.value)}
            ></input>
            <DropdownButton
              as={InputGroup.Append}
              title={`.${currentFormat}`}
              id="input-group-dropdown-1"
              className="raw-dropdown"
            >
              {exportFormats.map((d) => {
                return (
                  <Dropdown.Item key={d} onClick={() => changeFormat(d)}>
                    .{d}
                  </Dropdown.Item>
                )
              })}
            </DropdownButton>
          </InputGroup>
        </div>

        <div className="col col-sm-2">
          <button
            className="btn btn-primary btn-block raw-btn"
            onClick={downloadViz}
          >
            ダウンロード
          </button>
        </div>

        <div className="col col-sm-2">
          <button
            className="btn btn-primary btn-block raw-btn"
            onClick={deployViz}
          >
            デプロイ
          </button>
        </div>

        <div className="col col-sm-4">
          <button
            className="btn btn-warning btn-block raw-btn"
            onClick={publicViz}
          >
            公開
            {!publicFinished && (
              <span style={{ color: 'var(--danger)' }}>（※個人情報が含まれていないことを確認してください！）</span>
            )}
            {publicFinished && (
              <span style={{ color: 'var(--danger)' }}>（ありがとうございます！チャートが公開されました！）</span>
            )}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="d-flex align-items-center nowrap col-sm-1">
          チャート概要
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control text-field"
            value={chartDesc}
            onChange={(e) => {
              setChartDesc(e.target.value)
            }}
            placeholder="デプロイ または 公開 の場合、チャート概要を入力してください"
          ></input>
        </div>
        <div className="d-flex align-items-center nowrap col-sm-1">
        </div>
        <div className="col col-sm-2 d-flex">
        </div>
      </div>
      <div className="row">
        <div className="d-flex align-items-center nowrap col-sm-1">
          チャートURL
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control text-field"
            value={chartUrl}
            readOnly={true}
          ></input>
        </div>
        <div className="col col-sm-2 d-flex">
          {chartUrlCopyToClipboardButton}
        </div>
        <div className="d-flex align-items-center nowrap col-sm-1">
        </div>
      </div>
      <div className="row">
        <div className="d-flex align-items-center nowrap col-sm-1">
          共有URL
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control text-field"
            value={currentUrl}
            readOnly={true}
          ></input>
        </div>
        <div className="col col-sm-2 d-flex">
          {urlCopyToClipboardButton}
        </div>
        <div className="d-flex align-items-center nowrap col-sm-1">
          {USE_MINIO ? `※ 有効期限： ${parseInt(MINIO_DEF['expiry'] / 24 / 60 / 60).toFixed(0)} 日` : ''}
        </div>
      </div>
      <div className="row">
        <div className="d-flex align-items-center nowrap col-sm-1">
          埋め込みコード
        </div>
        <div className="col">
          <input
            type="text"
            className="form-control text-field"
            value={currentEmbed}
            readOnly={true}
          ></input>
        </div>
        <div className="col col-sm-2 d-flex">
          {embedCopyToClipboardButton}
        </div>
        <div className="d-flex align-items-center nowrap col-sm-1">
        </div>
      </div>
    </>
  )
}
